import * as React from 'react';
import { graphql } from 'gatsby';

import parse from 'html-react-parser';
import Page from '../components/Page/Page';
import Container from '../components/Container';
import IndexLayout from '../layouts';

import GQL from '../graphql-types';
import css from './about.module.scss';

interface AboutData {
  page: GQL.ContentfulPageConnection;
}

export interface AboutProps {
  data: AboutData;
}

const AboutPage = ({ data }: AboutProps) => {
  return (
    <IndexLayout>
      <Page className={css.page}>
        <Container>
          <h1>{data.page.title}</h1>
          <Container>{parse(data.page.content.childMarkdownRemark.html)}</Container>
        </Container>
      </Page>
    </IndexLayout>
  );
};

export default AboutPage;

export const pageQuery = graphql`
  query($lang: String) {
    page: contentfulPage(node_locale: { eq: $lang }, slug: { eq: "about" }) {
      ...PageFragment
    }
  }
`;
